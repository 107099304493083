/**
 * @class StyleToggleOnScroll - Toglle style on scroll
*/
class StyleToggleOnScroll {
  /**
   * @constructs ClassToggleOnScroll
   * @param {Object} el - The HTML element attach to the ClassToggleOnScroll
  */
  constructor(el) {
    this.el = el;
    if (!this.el.hasAttribute("data-scroll-trigger")) {
      this.scrollTrigger = 10
    } else {
      this.scrollTrigger = el.dataset.scrollTrigger
    }
    if (!this.el.hasAttribute("data-scroll-style")) {
      this.scrollStyle = null
    } else {
      this.scrollStyle = el.dataset.scrollStyle
    }
    if (!this.el.hasAttribute("data-scroll-style-base")) {
      this.scrollStyleBase = null
    } else {
      this.scrollStyleBase = el.dataset.scrollStyleBase
    }
    if (!this.el.hasAttribute("data-scroll-style-active")) {
      this.scrollStyleActive = null
    } else {
      this.scrollStyleActive = el.dataset.scrollStyleActive
    }
    this.document = document;
    this.initEvents();
  }

  /**
   * @function initEvents
   * Init the events for the Sidebar. 
   * Construct Process Call Order : 1/1
  */
  initEvents() {
    if (this.scrollStyle) {
      if (this.scrollStyleBase) {
        this.el.style[this.scrollStyle] = this.scrollStyleBase
      }
    }
    this.document.addEventListener("scroll", this.onEvent.bind(this));
  }

  /**
   * @function onEvent
   * Handle The Event based on window scroll
  */
  onEvent(){
    let scrollpos = window.scrollY;
    if(scrollpos > this.scrollTrigger){
      if (this.scrollStyle) {
        if (this.scrollStyleActive) {
          this.el.style[this.scrollStyle] = this.scrollStyleActive;
        }
      }      
    }
    else {
      if (this.scrollStyle) {
        if (this.scrollStyleBase) {
          this.el.style[this.scrollStyle] = this.scrollStyleBase;
        }
      }   
    } 
  }
}
export default StyleToggleOnScroll;
