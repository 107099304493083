/**
 * @class Utils - Utils Functions to help 
*/
class Utils {
    /**
     * @function addClass
     * Add Class to an HTML Element
     * @param {Object} elt - The HTML element
     * @param {string} className - The class to add
    */
    static addClass(elt, className) {
      if (typeof elt.classList !== "undefined") {
        elt.classList.add(className);
        return true;
      }
      return false;
    }
  
    /**
     * @function hasClass
     * Check if an HTML Element has a class
     * @param {Object} elt - The HTML element
     * @param {string} className - The class to check
    */
    static hasClass(elt, className) {
      if (typeof elt.classList !== "undefined") {
        return elt.classList.contains(className);
      }
      return false;
    }
  
    /**
     * @function removeClass
     * Remove Class to an HTML Element
     * @param {Object} elt - The HTML element
     * @param {string} className - The class to remove
    */
    static removeClass(elt, className) {
      if (typeof elt.classList !== "undefined") {
        elt.classList.remove(className);
        return true;
      }
      return false;
    }
  
    /**
     * @function toggleClass
     * Toggle Class to an HTML Element
     * @param {Object} elt - The HTML element
     * @param {string} className - The class to toggle
    */
    static toggleClass(elt, className, force = undefined) {
      if (typeof elt.classList !== "undefined") {
        if (typeof force === "undefined") {
          elt.classList.toggle(className);
        } else {
          elt.classList.toggle(className, force);
        }
        return true;
      }
      return false;
    }
  
    /**
     * @function camelToKebabCase
     * Change string from camel to kebab case
     * @param {string} str - The string to handle
    */
    static camelToKebabCase(str) {
      return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, "$1-$2").toLowerCase();
    }
  
    /**
     * @function elementIndex
     * Return the index of an element in an array based on node list
     * @param {array} nodeList - The Node List
     * @param {Object} searchElt - The Element to find
    */
    static elementIndex(nodeList, searchElt) {
      return Array.from(nodeList).findIndex(el => el === searchElt);
    }
  
    /**
     * @function disableBodyScroll
     * Disable Body Scroll
     * @param {Object} el - The el to handle
    */
    static disableBodyScroll(el) {
      disableBodyScroll(el);
    }
  
    /**
     * @function enableBodyScroll
     * Enable Body Scroll
     * @param {Object} el - The el to handle
    */
    static enableBodyScroll(el) {
      enableBodyScroll(el);
    }
  
    /**
     * @function unwrap
     * Unwrap an Element
     * @param {Object} wrapper - The el to handle
    */
    static unwrap(wrapper) {
      const parent = wrapper.parentNode;
      while (wrapper.firstChild) {
        parent.insertBefore(wrapper.firstChild, wrapper);
      }
      parent.removeChild(wrapper);
    }
  
    /**
     * @function wrap
     * Wrap an Element
     * @param {Object} el - The el to handle
     * @param {Object} wrapper - The wrapper element to handle
    */
    static wrap(el, wrapper) {
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);
    }
  
    /**
     * @function ieVersion
     * Check if is navvigator version
     * @param {string} uaString - The user agent information
    */
    static ieVersion(uaString) {
      uaString = uaString || navigator.userAgent;
      var match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
      if (match) return parseInt(match[2]);
    }

    /**
     * @function hasWhiteSpace
     * Check if the string has white space
     * @param {string} s - The string tio ckeck
    */
    static hasWhiteSpace(s) {
      return s.indexOf(' ') >= 0;
    }

    /**
     * @function splitStringToArray
     * Transform a string in array based on spliter (default ' ' => white space)
     * @param {string} s - The string to tranform
     * @param {string} slicer - The slicer to user
    */
    static splitStringToArray(s, slicer = ' ') {
      const stingToarray = s.split(slicer);
      return stingToarray;
    }
  }
  
  export default Utils;
  