import Utils from "../../helpers/Utils";
/**
 * @class Navbar - To handle a nav .
*/
class Navbar {
  /**
   * @constructs Navbar
   * @param {Object} el - The HTML element attach to the Navbar
   * @param {number} windowSizeLimit - The Window Size Limit to trigger navbar reduce
   * @param {string} triggerBtnClass - The Trigger Button CSS class
   * @param {string} triggerBtnActiveClass - The Trigger Button Active CSS class
   * @param {string} navbarOpenClass - The Nav Bar Open CSS Class
   * @param {string} navbarCloseClass - The Nav Bar Open CSS Class
  */
  constructor(
    el,
    windowSizeLimit = 767,
    triggerBtnClass = "nav-btn-menu-trigger",
    triggerBtnActiveClass = "-active",
    navbarOpenClass = "-open",
    navbarCloseClass = "-close"
  ) {
    this.el = el;
    if (!this.el.hasAttribute("data-window-size-limit")) {
      this.windowSizeLimit = windowSizeLimit
    } else {
      this.windowSizeLimit = el.dataset.windowSizeLimit
    }
    if (!this.el.hasAttribute("data-trigger-btn-class")) {
      this.triggerBtnClass = triggerBtnClass
    } else {
      this.triggerBtnClass = el.dataset.triggerBtnClass
    }
    if (!this.el.hasAttribute("data-trigger-btn-active-class")) {
      this.triggerBtnActiveClass = triggerBtnActiveClass
    } else {
      this.triggerBtnActiveClass = el.dataset.triggerBtnActiveClass
    }
    if (!this.el.hasAttribute("data-navbar-open-class")) {
      this.navbarOpenClass = navbarOpenClass
    } else {
      this.navbarOpenClass = el.dataset.navbarOpenClass
    }
    if (!this.el.hasAttribute("data-navbar-close-class")) {
      this.navbarCloseClass = navbarOpenClass
    } else {
      this.navbarCloseClass = el.dataset.navbarCloseClass
    }
    this.triggerBtns = document.getElementsByClassName(triggerBtnClass);
    this.window = window;
    this.initEvents();
  }

  /**
   * @function initEvents
   * Init the events for the Sidebar. 
   * Construct Navbar Call Order : 1/1
  */
  initEvents() {
    for (let i = 0; i < this.triggerBtns.length; i++) {
      const btn = this.triggerBtns[i];
      btn.addEventListener("click", this.onClick.bind(this));
    }
    this.window.addEventListener("load", this.onEvent.bind(this));
    this.window.addEventListener("resize", this.onEvent.bind(this));
  }

  /**
   * @function onEvent
   * Handle The Event based on window load and resize
  */
  onEvent() {
    Utils.removeClass(this.el, this.navbarOpenClass);
    for (let i = 0; i < this.triggerBtns.length; i++) {
      const btn = this.triggerBtns[i];
      Utils.removeClass(btn, this.triggerBtnActiveClass);
      btn.ariaExpanded = true;
    }
    if (this.window.innerWidth < this.windowSizeLimit) {
      Utils.addClass(this.el, this.navbarCloseClass);
      for (let i = 0; i < this.triggerBtns.length; i++) {
        const btn = this.triggerBtns[i];
        btn.ariaExpanded = false;
      }
    } else {
      Utils.removeClass(this.el, this.navbarCloseClass);
    }
  }

  /**
   * @function onClick
   * Handle The Event based on btn click
  */
  onClick() {
    for (let i = 0; i < this.triggerBtns.length; i++) {
      const btn = this.triggerBtns[i];
      var x = btn.getAttribute("aria-expanded"); 
      x = x == "true" ? "false" :  "true";
      btn.setAttribute("aria-expanded", x)
      Utils.toggleClass(btn, this.triggerBtnActiveClass);
    }
    Utils.toggleClass(this.el, this.navbarOpenClass);
    Utils.toggleClass(this.el, this.navbarCloseClass);
  }
}

export default Navbar;
