/**
 * @class Counter - To display a Counter
*/
class Counter {
  /**
   * @constructs Counter
   * @param {Object} el - The HTML element attach to the Counter
  */
  constructor(el) {
    this.el = el
    if (!this.el.hasAttribute("data-counter-target")) {
      this.counterTarget = 0
    } else {
      this.counterTarget = this.el.dataset.counterTarget
    }
    if (!this.el.hasAttribute("data-counter-from")) {
      this.counterFrom = 0
    } else {
      this.counterFrom = this.el.dataset.counterFrom
    }
    if (!this.el.hasAttribute("data-counter-speed")) {
      this.counterSpeed = 2500
    } else {
      this.counterSpeed = this.el.dataset.counterSpeed
    }
    this.counterCount = parseInt(this.counterFrom)
    this.counterAlreadyFired = false
    this.initEvents()
  }

  /**
   * @function initEvents
   * Init the events for the Counter. 
   * Construct Process Call Order : 1/1
  */
  initEvents() {
    document.addEventListener("DOMContentLoaded", this.handleScroll.bind(this))
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }


  /**
   * @function updateCounter
   * Handle The Counter Start
  */
  updateCounter() {
    let counterContainer = this.el.querySelector('.counter-number')
    this.counterTarget = parseInt(this.counterTarget)
    this.counterCount = this.counterCount
    const increment = this.counterTarget / this.counterSpeed
    this.counterCount = this.counterCount + increment
    counterContainer.innerText =  Math.ceil(this.counterCount)
    if (this.counterCount < this.counterTarget) {
      setTimeout(this.updateCounter(), this.counterSpeed)
    } else {
      counterContainer.innerText = this.counterTarget
    }
  }


  /**
   * @function isElementVisible
   * Function to determine if an element is visible in the web page
  */
  isElementVisible() {
    let scroll = window.scrollY || window.pageYOffset
    let boundsTop = this.el.getBoundingClientRect().top + scroll
    let viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    }
    let bounds = {
      top: boundsTop,
      bottom: boundsTop + this.el.clientHeight,
    }
    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    )
  }

  /**
   * @function isElementVisible
   * Function that will get fired uppon scrolling
  */
  handleScroll() {
      if (true === this.counterAlreadyFired) return
      if (!this.isElementVisible()) return
      this.updateCounter()
      this.counterAlreadyFired = true
  }
}

export default Counter;
