/**
 * @class LiquidSlider - To Create a slide image galery with arrow and dot to handle, and touch functionalities.
*/
class LiquidSlider {
  /**
   * @constructs LiquidSlider
   * @param {Object} el - The HTML element attach to the slider
   * @param {number} slideSpeed - The slide speed
   * @param {string} isActiveClass - The is-active class to add
   * @param {string} liquidSliderImgContainerClass - The Liquid Slider Container CSS Class (add . before the class => eg: .my-class)
   * @param {string} liquidSliderSlideClass - The Liquid Slider Slide CSS Class (add . before the class => eg: .my-class)
   * @param {string} liquidSliderArrowNavContainerClass - The Liquid Slider Arrow Navigation Container CSS Class (add . before the class => eg: .my-class)
   * @param {string} liquidSliderDotContainerClass - The Liquid Slider  Dot Container Container CSS Class (add . before the class => eg: .my-class)
   * @param {string} liquidSliderDotClass - The Liquid Slider Dot CSS Class (add . before the class => eg: .my-class)
   * @param {string} liquidSliderArrowPrevClass - The Liquid Slider Previous Arrow CSS Class (add . before the class => eg: .my-class)
   * @param {string} liquidSliderArrowNextClass - The Liquid Slider Previous Next CSS Class (add . before the class => eg: .my-class)
  */
  constructor(
    el,
    slideSpeed = 4000,
    isActiveClass = "is-active",
    liquidSliderImgContainerClass = ".liquid-slider__img-container",
    liquidSliderSlideClass = ".liquid-slider-slide",
    liquidSliderArrowNavContainerClass = ".liquid-slider__arrow-nav-container",
    liquidSliderDotContainerClass = ".liquid-slider-dot-container",
    liquidSliderDotClass = ".liquid-slider-dot",
    liquidSliderArrowPrevClass = ".liquid-slider-arrow--prev",
    liquidSliderArrowNextClass = ".liquid-slider-arrow--next"
  ) {
    this.el = el;
    this.isActiveClass = isActiveClass;
    this.liquidSliderImgContainerClass = liquidSliderImgContainerClass;
    this.liquidSliderSlideClass = liquidSliderSlideClass;
    this.liquidSliderArrowNavContainerClass = liquidSliderArrowNavContainerClass;
    this.liquidSliderDotContainerClass = liquidSliderDotContainerClass;
    this.liquidSliderDotClass = liquidSliderDotClass;
    this.liquidSliderArrowPrevClass = liquidSliderArrowPrevClass;
    this.liquidSliderArrowNextClass = liquidSliderArrowNextClass;

    this.img_width = this.el.clientWidth;
    this.imgs_container = this.el.querySelector(this.liquidSliderImgContainerClass);
    this.imgs = this.el.querySelectorAll(this.liquidSliderSlideClass);
    this.total_imgs = this.imgs.length;
    this.arrow_dot_container = this.el.querySelector(this.liquidSliderArrowNavContainerClass);
    this.dots_container = this.el.querySelector(this.liquidSliderDotContainerClass);
    this.prev_btn = this.el.querySelector(this.liquidSliderArrowPrevClass);
    this.next_btn = this.el.querySelector(this.liquidSliderArrowNextClass);
    this.slide_seconds = slideSpeed;
    this.current_index = 1;
    this.last_index;
    this.move_distance;
    this.timer;
    this.mouse_is_down = false;
    this.mouse_position_x = [0, 0];
    this.initEvents();
    this.init();
  }

  /**
   * @function init
   * Init the Slider Build Process. 
   * Construct Process Call Order : 2/2
  */
  init() {
    this.el.querySelector(`#dot-${this.current_index}`).classList.remove(this.isActiveClass);
    this.el.querySelector(`#dot-${this.current_index}`).ariaSelected = false;
    this.img_width = this.el.clientWidth;
    this.imgs.forEach(img => img.style.width = `${this.img_width}px`);
    this.imgs_container.style.width = `${this.img_width * this.total_imgs}px`;
    this.current_index = this.total_imgs;
    this.calculateNextDistance();
  }

  /**
   * @function initEvents
   * Init the events for the Slider. 
   * Construct Process Call Order : 1/2
  */
  initEvents() {
    this.next_btn.addEventListener('click', this.calculateNextDistance.bind(this));
    this.prev_btn.addEventListener('click', this.calculatePrevDistance.bind(this));
    this.dots_container.addEventListener('click', this.slideDotImages.bind(this));
    window.addEventListener('resize', this.init.bind(this));
    this.arrow_dot_container.addEventListener('mousedown', (e) => {
      this.mouse_is_down = true;
      clearInterval(this.timer);
      this.mouse_position_x = [e.clientX, 0];
      this.arrow_dot_container.style.cursor = '-webkit-grabbing';
    })
    this.arrow_dot_container.addEventListener('mousemove', (e) => {
      if (!this.mouse_is_down) return false; 
      this.createDraggingEffects(e);
    })
    this.arrow_dot_container.addEventListener('mouseup', (e) => {
      this.mouse_position_x[1] = e.clientX;
      this.dragAndSlideImages(e);
      this.mouse_is_down = false;
      this.arrow_dot_container.style.cursor = '';
    })
    this.arrow_dot_container.addEventListener('mouseleave', () => {
      if (!this.mouse_is_down) return false;
      this.mouse_position_x = [0, 0];
      this.dragAndSlideImages();
      this.mouse_is_down = false;
      this.arrow_dot_container.style.cursor = ''; 
    })
  }

  /**
   * @function calculateNextDistance
   * Function to handle the calculation of the distance of the next slide
  */
  calculateNextDistance() {
    this.move_distance = -(this.current_index * this.img_width);
    this.last_index = this.current_index;
  
    if (this.current_index == this.total_imgs) {
      this.current_index = 1;
      this.move_distance = 0;
    } else {
      this.current_index++;
    }
    this.slideImages();
  }

  /**
   * @function calculatePrevDistance
   * Function to handle the calculation of the distance of the previous slide
  */
  calculatePrevDistance() {
    if (this.current_index > 1) {
      this.last_index = this.current_index;
      this.current_index--;
      this.move_distance = this.move_distance + this.img_width;
    } else {
      this.last_index = this.current_index;
      this.current_index = this.total_imgs;
      this.move_distance = -((this.total_imgs - 1) * this.img_width);
    }
    this.slideImages();
  }

  /**
   * @function slideImages
   * @param {number} drag_distance - Optional - The distance to ride to the next or previous image 
   * Function to handle the slide between images
  */
  slideImages(drag_distance) {
    clearInterval(this.timer);
    const distance = drag_distance || this.move_distance;
    this.imgs_container.style.transform = `translateX(${distance}px)`;
    this.el.querySelector(`#dot-${this.last_index}`).classList.remove(this.isActiveClass);
    this.el.querySelector(`#dot-${this.last_index}`).ariaSelected = false;
    this.el.querySelector(`#dot-${this.current_index}`).classList.add(this.isActiveClass);
    this.el.querySelector(`#dot-${this.current_index}`).ariaSelected = true;

    this.timer = setInterval(this.calculateNextDistance, this.slide_seconds);
  }

  /**
   * @function slideDotImages
   * @param {object} e - Optional - The click event
   * Function to determinate the slide when a dot is clicked and slide to it
  */
  slideDotImages(e) {
    const dots = this.el.querySelectorAll(this.liquidSliderDotClass);
    for (var i = 1; i <= dots.length; i++) {
      if (e.target.id == `dot-${i}`) {
        this.last_index = this.current_index;
        this.current_index = i;
        this.move_distance = -((this.current_index - 1) * this.img_width);
      }
    }
    this.slideImages();
  }

  /**
   * @function createDraggingEffects
   * @param {object} e - Optional - The click event
   * Function to create Dragging Effect
  */
  createDraggingEffects(e) {
    this.mouse_position_x[1] = e.clientX;
    if (this.mouse_position_x[0] == this.mouse_position_x[1]) {
      return false;
    }
    let scrolled_distance = Math.abs((this.mouse_position_x[0] - this.mouse_position_x[1]));
    const weight = this.img_width / 12; // Make the effect of the image being dragged more clean. Larger is the divisor and worse is the effect 
    if (this.mouse_position_x[0] > this.mouse_position_x[1]) {
        scrolled_distance = this.move_distance - scrolled_distance - weight;
    } else { 
        scrolled_distance = this.move_distance + scrolled_distance + weight;
    }
    this.slideImages(scrolled_distance); 
  }

  /**
   * @function dragAndSlideImages
   * Function to handle Dragging Effect and slide between image by dragging them
  */
  dragAndSlideImages() {
    if (this.mouse_position_x[0] == this.mouse_position_x[1]) {
      this.slideImages();
      return false;
    }
    if (Math.abs(this.mouse_position_x[0] - this.mouse_position_x[1]) < 30) {
      this.slideImages();
      return false;
    }
    if (this.mouse_position_x[0] > this.mouse_position_x[1]) {
      this.calculateNextDistance();
    } else {
      this.calculatePrevDistance();
    }
  }
}
export default LiquidSlider;
