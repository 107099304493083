// 1  - Import Font
//import './font.js';

// 2  - Import Component and Utils
import ClassToggleOnScroll from './helpers/ClassToggleOnScroll';
import StyleToggleOnScroll from './helpers/StyleToggleOnScroll';
import LiquidSlider from './components/slider/LiquidSlider';
import LiquidCarousel from './components/slider/LiquidCarousel';
import Navbar from './components/layout/Navbar';
import Counter from './components/counter/Counter';

// 4- Load Component and Utils
Array.from(document.getElementsByClassName("class-toggle-on-scroll")).forEach(
  el => {
    new ClassToggleOnScroll(el);
  }
);
Array.from(document.getElementsByClassName("style-toggle-on-scroll")).forEach(
  el => {
    new StyleToggleOnScroll(el);
  }
);
Array.from(document.getElementsByClassName("liquid-slider")).forEach(
  el => {
    new LiquidSlider(el);
  }
);
Array.from(document.getElementsByClassName("liquid-carousel")).forEach(
  el => {
    new LiquidCarousel(el);
  }
);
Array.from(document.getElementsByClassName("nav-bar")).forEach(
  el => {
    new Navbar(el);
  }
);
Array.from(document.getElementsByClassName("counter-player")).forEach(
  el => {
    new Counter(el);
  }
);

// 4 - other scripts
