/**
 * @class LiquidCarousel - To Create a Carousel image galery with arrow and dot to handle, and touch functionalities.
*/
class LiquidCarousel {
  /**
   * @constructs LiquidCarousel
   * @param {Object} el - The HTML element attach to the carousel
   * @param {number} scrollLeft - Scroll left Init default => 0
   * @param {number} scrollSpeed - Scroll Speed Init default => 4
  */
  constructor(
    el,
    scrollLeft = 0,
    scrollSpeed = 4
  ) {
    this.el = el
    this.carouselItems = this.el.innerHTML
    // Set up animation
    this.scrollLeft = scrollLeft
    this.scrollSpeed = scrollSpeed // Adjust the scroll speed as needed
    this.lastTimestamp = null
    this.init()
  }

  /**
   * @function init
   * Init the Carousel Build Process. 
   * Construct Process Call Order : 1/1
  */
  init() {
    const carouselContainer = this.el
    // Clone the carousel content to create a continuous loop
    this.el.innerHTML += this.carouselItems
    let scrollLeft = this.scrollLeft
    const scrollSpeed = this.scrollSpeed
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame
    let myReq
    let mouseEntered = false

    this.el.addEventListener('mouseenter', function() {
      mouseEntered = true
      myReq = requestAnimationFrame(animateCarousel)
    })
    this.el.addEventListener('mouseleave', function() {
        mouseEntered = false
        myReq = requestAnimationFrame(animateCarousel)
    })

    /**
     * @function animateCarousel
     * @param {number} timestamp - Timestamp
     * Function to handle the slide between images
    */
    function animateCarousel(timestamp) {
      if (mouseEntered === false) {
        if (!lastTimestamp) {
            lastTimestamp = timestamp
        }
        const deltaTime = timestamp - lastTimestamp
        lastTimestamp = timestamp

        scrollLeft += scrollSpeed * deltaTime / 60; // Normalize speed
        if (scrollLeft >= carouselContainer.scrollWidth / 2) {
            scrollLeft = 0
        }
        carouselContainer.style.transform = `translateX(-${scrollLeft}px)`
        myReq = requestAnimationFrame(animateCarousel)
      }
    }

    let lastTimestamp = this.lastTimestamp
    myReq = requestAnimationFrame(animateCarousel)
  }

}
export default LiquidCarousel;
