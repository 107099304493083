import Utils from "./Utils";

/**
 * @class ClassToggleOnScroll - Toglle class on scroll
*/
class ClassToggleOnScroll {
  /**
   * @constructs ClassToggleOnScroll
   * @param {Object} el - The HTML element attach to the NavbarReduceOnClick
  */
  constructor(el) {
    this.el = el;
    if (!this.el.hasAttribute("data-scroll-trigger")) {
      this.scrollTrigger = 10
    } else {
      this.scrollTrigger = el.dataset.scrollTrigger
    }
    if (!this.el.hasAttribute("data-scroll-add-class")) {
      this.scrollAddClass = null
    } else {
      this.scrollAddClass = el.dataset.scrollAddClass
    }
    if (!this.el.hasAttribute("data-scroll-remove-class")) {
      this.scrollRemoveClass = null
    } else {
      this.scrollRemoveClass = el.dataset.scrollRemoveClass
    }
    if (!this.el.hasAttribute("data-scroll-group-class")) {
      this.scrollGroupClass = null
    } else {
      this.scrollGroupClass = el.dataset.scrollGroupClass
    }
    this.document = document;
    this.initEvents();
  }

  /**
   * @function initEvents
   * Init the events for the Sidebar. 
   * Construct Process Call Order : 1/1
  */
  initEvents() {
    this.document.addEventListener("scroll", this.onEvent.bind(this));
  }

  /**
   * @function onEvent
   * Handle The Event based on window scroll
  */
  onEvent(){
    let scrollpos = window.scrollY;
    if(scrollpos > this.scrollTrigger){
      if (this.scrollAddClass) {
        if (Utils.hasWhiteSpace(this.scrollAddClass)) {
          let addClasses = Utils.splitStringToArray(this.scrollAddClass)
          for (let i = 0; i < addClasses.length; i++) {
            Utils.addClass(this.el, addClasses[i]);
          }
        } else {
          Utils.addClass(this.el, this.scrollAddClass);
        }
      }
      if (this.scrollRemoveClass) {
        if (Utils.hasWhiteSpace(this.scrollRemoveClass)) {
          let removeClasses = Utils.splitStringToArray(this.scrollRemoveClass)
          for (let i = 0; i < removeClasses.length; i++) {
            Utils.removeClass(this.el, removeClasses[i]);
          }
        } else {
          Utils.removeClass(this.el, this.scrollRemoveClass);
        }
      }
      if (this.scrollGroupClass) {
        let scrollGroup = document.getElementsByClassName(this.scrollGroupClass);
        for (let i = 0; i < scrollGroup.length; i++) {
          if (scrollGroup[i].hasAttribute("data-scroll-add-class")) {
            if (Utils.hasWhiteSpace(scrollGroup[i].dataset.scrollAddClass)) {
              let addClasses = Utils.splitStringToArray(scrollGroup[i].dataset.scrollAddClass)
              for (let index = 0; index < addClasses.length; index++) {
                Utils.addClass(scrollGroup[i], addClasses[index]);
              }
            } else {
              Utils.addClass(scrollGroup[i], scrollGroup[i].dataset.scrollAddClass);
            }
          }
          if (scrollGroup[i].hasAttribute("data-scroll-remove-class")) {
            if (Utils.hasWhiteSpace(scrollGroup[i].dataset.scrollRemoveClass)) {
              let removeClasses = Utils.splitStringToArray(scrollGroup[i].dataset.scrollRemoveClass)
              for (let index = 0; index < removeClasses.length; index++) {
                Utils.removeClass(scrollGroup[i], removeClasses[index]);
              }
            } else {
              Utils.removeClass(scrollGroup[i], scrollGroup[i].dataset.scrollRemoveClass);
            }
          }
        }
      }
    }
    else {
      if (this.scrollAddClass) {
        if (Utils.hasWhiteSpace(this.scrollAddClass)) {
          let addClasses = Utils.splitStringToArray(this.scrollAddClass)
          for (let i = 0; i < addClasses.length; i++) {
            Utils.removeClass(this.el, addClasses[i]);
          }
        } else {
          Utils.removeClass(this.el, this.scrollAddClass);
        }
      }
      if (this.scrollRemoveClass) {
        if (Utils.hasWhiteSpace(this.scrollRemoveClass)) {
          let removeClasses = Utils.splitStringToArray(this.scrollRemoveClass)
          for (let i = 0; i < removeClasses.length; i++) {
            Utils.addClass(this.el, removeClasses[i]);
          }
        } else {
          Utils.addClass(this.el, this.scrollRemoveClass);
        }
      }
      if (this.scrollGroupClass) {
        let scrollGroup = document.getElementsByClassName(this.scrollGroupClass);
        for (let i = 0; i < scrollGroup.length; i++) {
          if (scrollGroup[i].hasAttribute("data-scroll-add-class")) {
            if (Utils.hasWhiteSpace(scrollGroup[i].dataset.scrollAddClass)) {
              let addClasses = Utils.splitStringToArray(scrollGroup[i].dataset.scrollAddClass)
              for (let index = 0; index < addClasses.length; index++) {
                Utils.removeClass(scrollGroup[i], addClasses[index]);
              }
            } else {
              Utils.removeClass(scrollGroup[i], scrollGroup[i].dataset.scrollAddClass);
            }
          }
          if (scrollGroup[i].hasAttribute("data-scroll-remove-class")) {
            if (Utils.hasWhiteSpace(scrollGroup[i].dataset.scrollRemoveClass)) {
              let removeClasses = Utils.splitStringToArray(scrollGroup[i].dataset.scrollRemoveClass)
              for (let index = 0; index < removeClasses.length; index++) {
                Utils.addClass(scrollGroup[i], removeClasses[index]);
              }
            } else {
              Utils.addClass(scrollGroup[i], scrollGroup[i].dataset.scrollRemoveClass);
            }
          }
        }
      }  
    } 
  }
}

export default ClassToggleOnScroll;
